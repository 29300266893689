<template lang="pug">
.code-insert-notifications(v-if="loading || error")
  om-toast.m-auto(loading v-if="loading" :closable="false") {{ message }}
  om-toast(v-else :closable="false" :allowSeparator="false" color="danger" type="icon")
    div(v-html="message")
</template>

<script>
  export default {
    name: 'CodeInsertNotifications',
    props: {
      platform: { type: String, default: null, required: true },
      status: {
        type: String,
        default: null,
        validator: (prop) => [null, 'loading', 'error'].includes(prop),
      },
    },
    computed: {
      message() {
        return this.platform === 'shopify'
          ? this.$t(`codeInsertV2.${this.platform}.messages.${this.status}`)
          : this.$t(`codeInsertV2.messages.${this.status}`);
      },

      loading() {
        return this.status === 'loading';
      },

      error() {
        return this.status === 'error';
      },
    },
  };
</script>

<style lang="sass">
  .code-insert-notifications
    margin: 0 auto auto
    width: fit-content
    max-width: min(50rem, 80vw)
    position: sticky
    top: 0.75rem
    z-index: 2
    @container (max-width: 1000px)
      z-index: 0
      margin: 1rem auto
      position: relative
      top: 2.5rem

    .ds-toast
      border: none
</style>
